<template>
  <div class="flex justify-center items-center flex-col gap-6">
    <div class="flex justify-center items-center flex-col">
      <span class="oswald-font text-[120px] text-center leading-[120px]">LVZ</span>
      <span class="oswald-font">Serviços, Projetos e Soluções</span>
    </div>
    
    <DSCard class="border min-w-[280px]">
      <h1 class="text-center text-xl "><b>HCM HOTEL</b></h1>
      <p class="text-center text-sm mb-5" >Painel ADM - KPI - Cardápio</p>
      <form @submit.prevent="addItem">
        <div class="flex justify-center items-center flex-col mb-5">
          <label for="name">Credencial</label>
          <input type="text" class="rounded-[2px] bg-[#fff5eebf]" id="name" v-model="login.email" />
        </div>
        <div class="flex justify-center items-center flex-col mb-5">
          <label for="pass">Senha</label>
          <input type="text" class="rounded-[2px] bg-[#fff5eebf]" id="pass" v-model="login.password" />
        </div>
      </form>
      <DSButton
        class="flex justify-center items-center"
        @_click="copyItems"
      >
        Entrar
      </DSButton>
    </DSCard>


    
  </div>
</template>

<script>

import DSButton from "@/components/ui/DSButton.vue";
import DSCard from "@/components/ui/DSCard.vue";

export default {

  components: {
    DSButton,
    DSCard,
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
   
  },
};
</script>

<style>
/* Estilos adicionais conforme necessário */
</style>
