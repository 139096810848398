<template>
  <section class="card-style">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "DSCard",
  comments: {
  },
  props: {
  },
  data() {
    return {
      // Here you can define your data
    };
  },

  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
.card-style {
  color: #141414;
  background-color: #ffdfc91a;
  cursor: pointer;
  border-radius: 2px;
  border: #544b45 1px solid;

  padding: 18px;
}
</style>
