import { render, staticRenderFns } from "./DishDetailsModal.vue?vue&type=template&id=7b8c4ea9&scoped=true"
import script from "./DishDetailsModal.vue?vue&type=script&lang=js"
export * from "./DishDetailsModal.vue?vue&type=script&lang=js"
import style0 from "./DishDetailsModal.vue?vue&type=style&index=0&id=7b8c4ea9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8c4ea9",
  null
  
)

export default component.exports