<template>
  <section class="header-info-spacing">
    <section class="header-info">
      <div class="info-container" ref="infoContainer">
        <transition name="fade" mode="out-in" :key="currentAd.text">
          <div :key="currentAd.text" class="info" :ref="(el) => { this.$refs.info = el;}">
            {{ currentAd.text }}
          </div>
        </transition>
      </div>
    </section>
  </section>
</template>

<script>
import headerAds from "../../utils/headerAds/headerAds.json";

export default {
  name: "headerAds",
  data() {
    return {
      ads: headerAds.ads,
      currentAdIndex: 0,
      rotateTime: 3200,
      adQueue: [],
    };
  },

  computed: {
    currentAd() {
      return this.filteredAds[this.currentAdIndex];
    },
  },
  created() {
    this.filteredAds = this.ads.filter((ad) => ad.isActive);
  },
  mounted() {
    setTimeout(this.startRotation, this.rotateTime);
  },
  methods: {
    startRotation() {
      this.nextAd();
    },
    async nextAd() {
      const container = this.$refs.infoContainer;
      const adElement = this.$refs.info;

      if (adElement?.offsetWidth > container?.offsetWidth) {
        await new Promise((resolve) => {
          adElement.animate(
            [
              { transform: `translateX(0px)` },
              {
                transform: `translateX(${
                  (-adElement.offsetWidth + container.offsetWidth) * 1.5
                }px)`,
              },
            ],
            {
              duration: this.rotateTime,
              fill: "forwards",
            }
          ).onfinish = resolve;
        });
      }

      this.advanceAd();
      setTimeout(this.nextAd, this.rotateTime);
    },

    advanceAd() {
      this.currentAdIndex = (this.currentAdIndex + 1) % this.filteredAds.length;
    },
  },
};
</script>

<style scoped>
.header-info-spacing {
  padding-top: 20px;
}

.header-info {
  background-color: #6f635a;
  height: 20px; /* Adjust based on actual height needed */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 49;
}

.info-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.info {
  color: #fdfdfd;
  font-size: 12px;
  white-space: nowrap;
  padding: 0 5px;
}
</style>
