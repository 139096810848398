<template>
  <section class="flex justify-center items-center w-full">
    <div class="items-scroll">
      <div v-for="tab in currentMenu.subMenu" :key="tab.section">
        <div @click="scrollToSection(tab.section)" class="">
          <span
            :alt="`Go to ${tab.section}`"
            class="this-item font-medium text-[14px] text-center uppercase"
          >
            {{ tab.section }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  name: "ItemsScroll",
  props: {
    currentMenu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: this.currentMenu.subMenu[0].name,
    };
  },
  computed: {},
  created() {},
  watch: {
    activeTab(value) {
      console.log(value);
      this.scrollToSection(value);
    },
  },
  methods: {
    scrollToSection(section) {
      const refName = this.assembleRefName(section);
      const el = document.getElementById(refName);
      const top = el.offsetTop - 130;
      window.scrollTo({ top, behavior: "smooth" });
    },
    assembleRefName(section) {
      return `section-${section.toLowerCase().replace(/\s/g, "-")}`;
    },
  },
};
</script>

<style scoped>
.items-scroll {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;
  padding: 6px 12px;
}

.this-item:active {
  transform: scale(0.8);
  font-size: 11.5px;
  font-weight: bold;
}
</style>
