<template>
    <section class="flex justify-center items-center">
        <div class="card max-w-[720px] custom-bg">
            <div v-if="item.image.url" class="card-image">
                <figure class="image">
                    <img :src="item.image.url" :alt="item.name ?? 'Imagem Do Prato'" class="w-full">
                </figure>
            </div>
            <div class="card-content">
                <div class="media mb-1">
                    <div class="media-left">
                        <figure class="image center h-[33px] w-[48px]">
                            <img crossorigin="anonymous" decoding="auto" src="@/assets/hcmLogo.png" alt="Image">
                        </figure>
                    </div>
                    <div v-if="item.name" class="media-content overflow-hidden">
                        <p class="title is-4">{{ item.name }}</p>
                    </div>
                </div>

                <div class="content">
                    <span v-if="item.image.description">{{ item.image.description }}</span>
                    <br v-if="item.image.description">
                    <small v-if="hasIngredients(item)" class="text-center">
                        {{ concatStrings(item.ingredients) }}
                    </small>
                    <br>
                    <small>R$ {{ item.price }}</small>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "DishDetailsModal",
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isCardModalActive: true
        }
    },
    created() {
        console.log(this.item)
    },
    methods: {
        hasIngredients(item) {
            const ingredients = item.ingredients;
            return ingredients.length > 0 ? ingredients : "";
        },
        concatStrings(arr) {
            return arr.join(" - ");
        },
    }
}
</script>

<style scoped>
.card-content {
    padding: 1rem;
}

.custom-bg {
    background-color: #ffe8d5;
}
</style>