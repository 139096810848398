import { render, staticRenderFns } from "./SubHeader.vue?vue&type=template&id=0bc626a8&scoped=true"
import script from "./SubHeader.vue?vue&type=script&lang=js"
export * from "./SubHeader.vue?vue&type=script&lang=js"
import style0 from "./SubHeader.vue?vue&type=style&index=0&id=0bc626a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc626a8",
  null
  
)

export default component.exports