<template>
  <div class="menu-card">
    <div v-for="(item, index) in items" :key="index">
      <h2
        class="font-medium text-[23px] italic text-center uppercase tracking-widest"
      >
        {{ item.title }}
      </h2>
      <MenuItems :items="item.items" />
    </div>
  </div>
</template>

<script>
import MenuItems from "./MenuItems.vue";

export default {
  name: "MenuCard",
  components: {
    MenuItems,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    // TODO: select language based on store state
  },
  created() {},
};
</script>

<style scoped>
.menu-card {
  width: 100%;
  height: 100%;
  padding: 6px;
}
</style>
