<template>
  <div class="flex justify-center items-center flex-col gap-6">
    <h1><b>Criador de Itens</b></h1>

    <form @submit.prevent="addItem">
      <div class="flex justify-center items-center flex-col mb-5">
        <label for="name">Nome do Prato:</label>
        <input type="text" id="name" v-model="newItem.name" />
      </div>

      <div class="flex justify-center items-center flex-col mb-5">
        <label for="ingredient">Ingredientes:</label>
        <textarea id="ingredient" v-model="ingredientsInput"></textarea>
        <button
          class="bg-gray-500 text-black w-full px-5 py-1"
          @click.prevent="addIngredient"
        >
          Adicionar Ingrediente
        </button>
        <ul>
          <li v-for="(ingredient, index) in newItem.ingredients" :key="index">
            {{ ingredient }}
          </li>
        </ul>
      </div>

      <div class="flex justify-center items-center flex-col mb-5">
        <label for="variant">Variantes:</label>
        <input type="text" id="variant" v-model="variant" />
        <button
          class="bg-gray-500 text-black w-full px-5 py-1"
          @click.prevent="addVariant"
        >
          Adicionar Variante
        </button>
        <ul>
          <li v-for="(variant, index) in newItem.variants" :key="index">
            {{ variant }}
          </li>
        </ul>
      </div>

      <div class="flex justify-center items-center flex-col mb-5">
        <label for="price">Preço:</label>
        <input type="number" id="price" v-model.number="newItem.price" />
      </div>

      <div class="flex justify-center items-center flex-col mb-5">
        <label for="image">Imagem (URL):</label>
        <input type="text" id="image" v-model="newItem.image" />
      </div>

      <div class="flex justify-center items-center flex-row mb-5">
        <input type="checkbox" id="disabled" v-model="newItem.disabled" />
        <label for="disabled">Desabilitar Item</label>
      </div>

      <div class="flex justify-center items-center flex-row mb-5">
        <input
          type="checkbox"
          id="is_alternative"
          v-model="newItem.is_variants_alternative"
        />
        <label for="is_alternative">Variante alternativa</label>
      </div>

      <button type="submit" class="bg-gray-500 text-black w-full px-5 py-1">
        Adicionar Item.
      </button>
    </form>

    <button
      class="bg-gray-500 text-black w-full px-5 py-1"
      @click.prevent="copyItems"
    >
      Copiar
    </button>
    <h3>Itens Adicionados</h3>
    <pre>{{ items }}</pre>
    <button
      class="bg-gray-500 text-black px-5 py-1"
      @click.prevent="clearItems"
    >
      Limpar Itens
    </button>
    <button
      class="bg-gray-500 text-black px-5 py-1"
      @click.prevent="deleteLastItem"
    >
      Deletar Último Item
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      newItem: {
        name: "",
        is_variants_alternative: false,
        ingredients: [],
        variants: [],
        price: null,
        image: "",
        disabled: false,
      },
      ingredientsInput: "",
      variant: "",
    };
  },
  methods: {
    addItem() {
      this.items.push({ ...this.newItem });
      this.resetNewItem();
    },
    resetNewItem() {
      this.newItem = {
        name: "",
        ingredients: [],
        variants: [],
        price: null,
        image: "",
        disabled: false,
      };
      this.ingredientsInput = "";
      this.variant = "";
    },
    addIngredient() {
      if (this.ingredientsInput) {
        this.newItem.ingredients.push(this.ingredientsInput);
        this.ingredientsInput = "";
      }
    },
    addVariant() {
      if (this.variant) {
        this.newItem.variants.push(this.variant);
        this.variant = "";
      }
    },
    clearItems() {
      this.items = [];
    },
    // copy items object to clipboard removing [ ]
    copyItems() {
      const items = JSON.stringify(this.items, null, 2);
      const itemsWithoutBrackets = items.slice(1, items.length - 1);
      navigator.clipboard.writeText(itemsWithoutBrackets);
      this.$buefy.toast.open({
        message:
          "Copiado para a área de transferência <br> Copied to clipboard",
      });
    },
    deleteLastItem() {
      this.items.pop();
    },
  },
};
</script>

<style>
/* Estilos adicionais conforme necessário */
</style>
