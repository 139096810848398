<template>
    <section class="menu-items">
        <div v-for="(item, index) in items" class="" :key="index" :class="{
        hidden: item.disabled,
      }">
            <div class="flex justify-center items-start mt-[8px] mb-[0px]">
                <div class="text-center">
                    <h3 class="font-medium text-[17px] mb-0 uppercase italic">
                        {{ item.name }}
                    </h3>
                </div>
                <!-- <h3 class="text-right mb-0">R$ {{ item.price }}</h3> -->
            </div>
            <div class="px-4 flex justify-center items-center">
                <div v-if="hasIngredients(item)" class="text-center flex flex-row justify-center items-center">
                    <span class="font-medium text-[12px] normal-case">
                        {{ concatStrings(item.ingredients) }}
                    </span>
                </div>
                <div v-if="hasVariants(item)" class="text-center flex flex-col justify-center items-center">
                    <span v-for="(dish, index) in item.variants" :key="index" class="flex justify-between items-start">
                        <span v-if="!item.is_variants_alternative" class="font-medium text-[12px] normal-case">{{ dish
                            }}</span>
                        <span v-else class="font-medium text-[17px] mb-0 uppercase italic">- {{ dish }} -
                        </span>
                        <br />
                    </span>
                </div>
            </div>

            <div class="flex flex-col justify-center items-center mb-5 mt-1 gap-1">
                <button v-if="item.image.url || item.image.description" @click="handleDishDetailsModal(item)"
                    class="flex flex-row justify-center items-center text-black text-xs cursor-pointer rounded-xl border-b-[0.5px] border-t-[0.5px] pl-1 pr-1.5 py-0.5 border-[#544b45]"
                    aria-label="Ver Mais">
                    <div v-if="item.image.url" class="flex justify-center items-center">
                        <img :src="item.image.url" loading="lazy" class="w-4 h-4 rounded-full mr-1" />
                    </div>
                    <b-icon v-else icon="image-search-outline" size="is-small"></b-icon>
                    Ver Mais
                </button>
                <h3 v-if="item.price" class="text-right mb-0 text-[14px]">
                    R$ {{ item.price }}
                </h3>
            </div>
        </div>
        <b-modal v-model="showDishDetailsModal" width="90svw" class="dish-details-modal" aria-modal="true"
            aria-label="Dish Details Modal - Detalhes Do Prato"
            :on-cancel="() => this.handleDishDetailsModal({}, false)">
            <dish-details-modal v-if="showDishDetailsModal" :item="dishDetailsModalItem" />
        </b-modal>
    </section>
</template>

<script>
import DishDetailsModal from "@/components/ui/DishDetailsModal.vue";

export default {
  name: "MenuItems",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    DishDetailsModal,
  },
  data() {
    return {
      showDishDetailsModal: false,
      dishDetailsModalItem: {},
    };
  },
  computed: {},
  methods: {
    hasIngredients(item) {
      const ingredients = item.ingredients;
      return ingredients.length > 0 ? ingredients : "";
    },
    hasVariants(item) {
      if (!item.variants) return "";
      const variants = Object.values(item.variants);
      return variants.length > 0 ? variants : "";
    },
    concatStrings(arr) {
      return arr.join(" - ");
    },
    handleDishDetailsModal(item, state = true) {
      this.dishDetailsModalItem = item;
      this.showDishDetailsModal = state;
    },
  },
  created() {},
};
</script>

<style scoped>
.menu-items {
  width: 100%;
  height: 100%;
  padding: 6px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.dish-details-modal {
  z-index: 100;
}

.modal-card {
  max-height: calc(100vh - 80px) !important;
}
</style>
