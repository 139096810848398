<template>
    <section class="bg_banner">
        <div id="app" class="relative">
            <div class="app-container">
                <router-view></router-view>
            </div>
            <footer class="dev-info">
                <p class="text-[14px]">Developed by: <span class="oswald-font">LVZ SPS</span></p>
                <p class="text-[14px]">contato@lvzsps.com</p>
                <p class="text-[14px] cursor-pointer rounded-xl border-b-[0.5px] border-t-[0.5px] pl-1 pr-1.5 py-0.5 border-[#544b45]"
                    @click="copyUrl">
                    <b-icon icon="content-copy" size="is-small" />
                    Copiar Contato
                </p>
            </footer>
        </div>
    </section>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    copyUrl() {
      const text = `🇧🇷 *LVZ SPS*\n> Serviços, Projetos e Soluções:\n\n✉️ Email:\n\`\`\`contato@lvzsps.com\`\`\` \n\n📲 WhatsApp:\n\`\`\`(83) 9 9332-7014\`\`\`\n\`\`\`(83) 9 9118-3872\`\`\``;
      navigator.clipboard.writeText(text);
      this.$buefy.toast.open({
        message:
          "Copiado para a área de transferência <br> Copied to clipboard",
      });
    },
  },
};
</script>

<style>
.app-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 12px;
}

#app {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}

.bg_banner {
  position: relative;
}
.bg_banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/Page_BG.jpg");
  background-size: 200%;
  filter: opacity(70%) contrast(70%);
}

.dev-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px 10px;
  color: #2f2f2f;
  z-index: 4;
}
</style>
