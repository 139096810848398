// src/router.js

import Vue from 'vue';
import Router from 'vue-router';

// Importe seus componentes aqui
import MenuWrapper from './pages/MenuWrapper.vue';
import ItemCreator from './pages/ItemCreator.vue';
import loginPage from './pages/Admin/loginPage.vue';

// Diga ao Vue para usar o Vue Router
Vue.use(Router);

// Defina suas rotas
export default new Router({
  mode: 'history', // para URLs limpas sem o hash (#)
  routes: [
    {
      path: '/',
      name: 'home',
      component: MenuWrapper
    },
    {
      path: '/criarPratos',
      name: 'criarPratos',
      component: ItemCreator
    },
    {
      path: '/login',
      name: 'login',
      component: loginPage
    }
    // Adicione mais rotas conforme necessário
  ]
});
