<template>
  <div class="menu-section">
    <!-- <h1 class="font-medium text-[26px] text-center uppercase tracking-[8px]">
      {{ currentMenu.section }}
    </h1> -->
    <div v-for="menu in currentMenu.subMenu" :key="menu.section">
      <h1
        :id="assembleRefName(menu.section)"
        class="font-medium text-[26px] text-center uppercase tracking-[8px] text-color-brown-secondary section-border-bottom"
      >
        {{ menu.section }}
      </h1>
      <MenuCard :items="menu.items" />
    </div>
  </div>
</template>

<script>
import MenuCard from "./MenuCard.vue";

export default {
  components: {
    MenuCard,
  },
  name: "MenuSection",
  props: {
    currentMenu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: this.currentMenu.subMenu[0].name,
    };
  },
  computed: {},
  created() {},
  watch: {
    activeTab(value) {
      console.log(value);
      this.scrollToSection(value);
    },
  },
  methods: {
    scrollToSection(section) {
      const ref = this.$refs[this.assembleRefName(section)][0];
      const top = ref.offsetTop - 100;
      window.scrollTo({ top, behavior: "smooth" });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    assembleRefName(section) {
      return `section-${section.toLowerCase().replace(/\s/g, "-")}`;
    },
  },
};
</script>

<style scoped>
.menu-section {
  width: 100%;
  height: 100%;
  padding: 6px;
}

.section-border-bottom {
  border-bottom: 0.5px solid #544b45;
  border-top: 0.5px solid #544b45;
}
.dropdown-style {
  background-color: #554b45;
  color: white;
  border: 0;
  min-width: 250px;
  text-transform: uppercase;
}

a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: #554b45;
  color: #fff;
}
.custom-tab-item .tab-content {
  display: none !important;
  padding: 0px !important;
}
</style>
