<template>
  <div class="subheader">
    <h1 class="font-medium mb-0 w-full">{{ msg }}</h1>
    <div class="flex flex-row justify-evenly items-center w-full mt-2">
      <!-- <p class="text-black text-xs cursor-not-allowed">
        <u>PT-BR</u> / <span class="text-gray-800 font-light">EN-EN</span>
      </p> -->
      <button
        class="text-black text-xs cursor-pointer rounded-xl border-b-[0.5px] border-t-[0.5px] pl-1 pr-1.5 py-0.5 border-[#544b45]"
        @click="copyUrl"
      >
        <b-icon icon="share-variant-outline" size="is-small"
          >Compartilhar</b-icon
        >
        Compartilhar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubHeader",
  props: {
    msg: String,
    currentMenu: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyUrl() {
      // 🥂🍹🍴
      const url = window.location.href;
      const socialMedia = [
        "https://instagram.com/hcmhotel",
        "https://instagram.com/merorestaurantehcm",
        "https://instagram.com/ucarooftop/",
      ];

      const org = "HCM Hotel";
      const kind = "Menu";
      const currentSection = `\`${this.currentMenu?.section ?? ""}\``;
      const callToAction =
        "🥂 Acesse nosso _cardápio_ clicando no *link*\n> Click the *link* to access our menu";
      const styledUrl = "* ```" + url + "```";
      const socialMediaCallToAction =
        "📲 *Siga-nos* nas redes sociais \n> Follow us on social media";
      const socialMediaStyled =
        "* ```" + socialMedia.join("```\n\n* ```") + "```";

      const text = `🇧🇷 *_${org} ${kind}_* ${currentSection} \n\n${callToAction}\n\n${styledUrl}\n\n${socialMediaCallToAction}\n\n${socialMediaStyled}`;
      navigator.clipboard.writeText(text);
      this.$buefy.toast.open({
        message:
          "Copiado para a área de transferência <br> Copied to clipboard",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subheader {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 30px;
}
.subheader h1 {
  font-size: 36px;
}
</style>
