<template>
  <section>
    <button
        class="button-style"
        :style="fontSizeStyle()"
        @click.prevent="emitEvent"
      >
        <slot></slot>
      </button>
  </section>
</template>

<script>
export default {
  name: "DSButton",
  data() {
    return {};
  },
  props: {
    fontSize: {
      type: String,
      default: "12px",
    },
    variant: {
      type: String,
      default: "primary-light",
      types: ["primary-light", "primary-dark", "secondary"],
    },
  },

  computed: {},
  created() {},
  mounted() {},
  methods: {
    emitEvent() {
      this.$emit("_click");
    },
  fontSizeStyle() {
    const theme = {
      "primary-light": {
        color: "#141414",
        backgroundColor: "#ffd",
        fontSize: this.fontSize,
      },
      "primary-dark": {
        color: "#fff",
        backgroundColor: "#544b45",
        fontSize: this.fontSize,
      },
      secondary: {
        color: "#141414",
        backgroundColor: "#ffdfc91a",
        fontSize: this.fontSize,
      },
    }
    return {
      color: theme[this.variant].color,
      fontSize: this.fontSize,
    };

  },
  },
};
</script>

<style scoped>
.button-style {
  cursor: pointer;
  border-radius: 6px;
  border-top: #544b45 1px solid;
  border-bottom: #544b45 1px solid;

  padding: 2px 8px;
}

</style>
